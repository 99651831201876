import EmployeeBirthday from '../../components/common/employee-birthday';
import { useEffect, useState } from 'react';
import { HolidayList, LeaveLog, MetricType, TodayStatusCounts } from '../../types';
import 'react-calendar/dist/Calendar.css';
import EmployeesHoliday from '../../components/common/employees-holiday';
import { useUserContext } from '../../context/user';
import Layout from '../../layout';
import hrApi from '../../services/hr-module';
import { TimeCard } from '../../components/common/time-card';
import { AccordionGroup } from '../../components/accordion';
import { CustomCard } from '../../components/card';
import EmployeeAttendance from '../../components/common/employee-attendance';
import EmployeeAttendanceWeek from '../../components/common/employee-attendance-week';
import { LeaveStatus, LeaveStatusSkeleton } from '../../components/common/leave-status';
import UpcomingHolidays from '../../components/common/upcoming-holiday';
import { getDateRange } from '../../utils/helpers';
import useLeaveLogs from '../../hooks/use-leaves';
import { ComponentLoading } from '../../components/component-loading';

const AdminDashboard = () => {
    const { user } = useUserContext();
    const [employeesOnHolidays, setEmployeesOnHolidays] = useState<LeaveLog[]>([])
    const [holidayList, setHolidayList] = useState<HolidayList[]>([])
    const [pendingApproval, setPendingApproval] = useState<LeaveLog[]>([])
    const [loadingPendingApproval, setLoadingPendingApproval] = useState<'loading' | 'error' | 'success'>('loading')
    const { loadingLeave, leaveLogs, getLeaveLogs } = useLeaveLogs()
    const [leavesHistory, setLeavesHistory] = useState<LeaveLog[]>([])
    const [weeklyAttendance, setWeeklyAttendance] = useState<WeeklyAttendance>({ on_leave: 0, wfh: 0, wfo: 0 })
    const [LeavesHistoryLoading, setLeavesHistoryLoading] = useState<'loading' | 'error' | 'success'>('loading')
    const [metric, setMetric] = useState<MetricType>({ average_check_in: "", average_check_out: "", average_hours: "", average_on_time: "" });
    const [employeesOnHolidaysLoading, setEmployeesOnHolidaysLoading] = useState<'loading' | 'error' | 'success'>('loading')
    const [todayAvailabilityStatus, setTodayAvailabilityStatus] = useState<TodayStatusCounts>({ absent: 0, late: 0, on_time: 0, wfh: 0, total: 0 })
    const [availabilityStatusLoading, setAvailabilityStatusLoading] = useState<boolean>(true);

    const getHolidays = async () => {
        const holidays: any = await hrApi.getHolidays()
        if (!!holidays) {
            setHolidayList(holidays.holidays)
        }
    }

    const getEmployeesOnHolidays = async () => {
        const data: any = await hrApi.getEmployeesOnHolidays()
        if (data && data.attendances) {
            setEmployeesOnHolidays(data.attendances)
            setEmployeesOnHolidaysLoading("success")
        } else {
            setEmployeesOnHolidaysLoading("error")
        }
    }

    const getEmployeesLeaveStatus = async (id: string) => {
        const data: any = await hrApi.getAdminEmployeesLeaveStatus(id)
        if (data && data.leaves) {
            setPendingApproval(data.leaves)
            setLoadingPendingApproval("success")
        } else {
            setLoadingPendingApproval("error")
        }
    }

    const getLeavesHistory = async (empID: string) => {
        const data: any = await hrApi.getLeavesHistory(empID)
        if (data && data.leaves) {
            setLeavesHistory(data.leaves)
            setLeavesHistoryLoading("success")
        } else {
            setLeavesHistoryLoading("error")
        }
    }

    const getWeeklyAttendance = async () => {
        const data: any = await hrApi.getWeeklyAttendance()
        if (data) {
            setWeeklyAttendance(data)
        }
    }

    const getEmployeeStatus = async () => {
        const data: any = await hrApi.getEmployeeStatus()
        if (data && data.availability_status) {
            setAvailabilityStatusLoading(false)
            setTodayAvailabilityStatus(data.availability_status)
        } else {
            setAvailabilityStatusLoading(false)
        }
    }

    const getMetrics = async () => {
        const data: any = await hrApi.getMetrics()
        if (data && data.employee_metrics) {
            setMetric(data.employee_metrics)
        }
    }

    useEffect(() => {
        getHolidays()
        getWeeklyAttendance()
        getEmployeeStatus()
        getMetrics()
        if (user.id) {
            getLeaveLogs(user.id)
            getEmployeesOnHolidays()
            getLeavesHistory(user.id)
            getEmployeesLeaveStatus(user.id)
        }
    }, [user]);

    return (
        <Layout>
            <div className="flex justify-between items-center mb-5">
                <h2 className="text-xl font-semibold capitalize">Organizational time stats (Monthly)</h2>
            </div>
            <div className="grid grid-cols-4 gap-6 mb-6">
                <TimeCard colorClass="fill-[#684A7E]" title="Average Hours" value={metric.average_hours} />
                <TimeCard colorClass="fill-[#14804A]" title="On-time Arrival" value={metric.average_on_time} />
                <TimeCard colorClass="fill-[#14804A]" title="Average Check-in" value={metric.average_check_in + " min"} />
                <TimeCard colorClass="fill-[#FF3D00]" title='Average Check-out' value={metric.average_check_out + " min"} />
            </div>
            <div className="grid grid-cols-12 gap-6 items-start">
                <div className="col-span-8 grid grid-cols-2 gap-6">
                    <EmployeeAttendanceWeek {...weeklyAttendance} />
                    <EmployeesHoliday
                        employeesLoading={employeesOnHolidaysLoading}
                        employees={employeesOnHolidays}
                    />
                    <CustomCard className='col-span-2'>
                        <h2 className="heading2">Approval Status</h2>
                        <AccordionGroup accordions={[
                            {
                                title: "Leave Status",
                                content:
                                    <ComponentLoading
                                        isLading={loadingLeave === 'loading'}
                                        error={loadingLeave === 'error'}
                                        children={leaveLogs.map((item, key) => {
                                            const dateRange = getDateRange(item.start_date, item.end_date, holidayList);
                                            return (
                                                <LeaveStatus key={key} leave={item} dateRange={dateRange} userId={''} isExpanded={true} isView
                                                />
                                            )
                                        })}
                                        childrenSkeleton={<LeaveStatusSkeleton />}
                                    />
                            }
                        ]} />
                        <div className='bg-[#F6F6F6] h-[2px]'></div>
                        <AccordionGroup accordions={[
                            {
                                title: "Pending Approval",
                                content:
                                    <ComponentLoading
                                        isLading={loadingPendingApproval === 'loading'}
                                        error={loadingPendingApproval === 'error'}
                                        children={
                                            pendingApproval?.map((item, key) => {
                                                const dateRange = getDateRange(item.start_date, item.end_date, holidayList);
                                                return (
                                                    <LeaveStatus
                                                        key={key}
                                                        leave={item}
                                                        dateRange={dateRange}
                                                        userId={user.id}
                                                        isLeaveApproval={true}
                                                        isExpanded={true}
                                                    />
                                                )
                                            })
                                        }
                                        childrenSkeleton={<LeaveStatusSkeleton />}
                                    />
                            }
                        ]} />
                        <div className='bg-[#F6F6F6] h-[2px]'></div>
                        <AccordionGroup accordions={[
                            {
                                title: "History",
                                content: <ComponentLoading
                                    isLading={LeavesHistoryLoading === 'loading'}
                                    error={LeavesHistoryLoading === 'error'}
                                    children={leavesHistory?.map((item, key) => {
                                        const dateRange = getDateRange(item.start_date, item.end_date, holidayList);
                                        return (
                                            <LeaveStatus key={key} leave={item} dateRange={dateRange} userId={''} isExpanded={true} isView />
                                        )
                                    })}
                                    childrenSkeleton={<LeaveStatusSkeleton />}
                                />
                            },
                        ]} />
                    </CustomCard>
                </div>
                <div className="col-span-4 grid gap-6">
                    <EmployeeBirthday />
                    <EmployeeAttendance
                        loading={availabilityStatusLoading}
                        tag='Today'
                        absent={todayAvailabilityStatus?.absent}
                        totalEmp={todayAvailabilityStatus.total}
                        wfh={todayAvailabilityStatus?.wfh}
                        lateAttendance={todayAvailabilityStatus?.late}
                        onTime={todayAvailabilityStatus?.on_time}
                    />
                    <UpcomingHolidays holidays={holidayList} />
                </div>
            </div >
        </Layout >
    );
}

export default AdminDashboard;

type WeeklyAttendance = {
    on_leave: number
    wfh: number
    wfo: number
}