import { Roles } from '../config';
import Billing from '../pages/finance/bills';
import AdminDashboard from '../pages/dashboard/admin-dashboard';
import ManagerDashboard from '../pages/dashboard/manager-dashboard';
import UserDashboard from '../pages/dashboard/user-dashboard';
import Invoices from '../pages/finance/Invoices';
import LeaveManagement from '../pages/leave-management';
import TaskEdit from '../pages/task-edit';
import TaskStats from '../pages/task-stats';
import CreateBill from '../pages/finance/bill-create';
import BillPreview from '../pages/finance/bill-preview';
import CreateInvoice from '../pages/finance/Invoice-create';
import InvoicePreview from '../pages/finance/Invoice-preview';
import TaskView from '../pages/task-view';

// Define the type for route config
export type RouteConfig = {
    component: React.ComponentType;
    path: string;
    title?: string | React.ReactNode;
    icon?: React.ReactNode;
    exact?: boolean;
    permission?: string[];
    children?: RouteConfig[];
};

// Route configuration
const routes: RouteConfig[] = [
    {
        component: AdminDashboard,
        path: '/dashboard',
        title: 'Dashboard',
        exact: true,
        icon: "",
        permission: [
            Roles.ADMIN,
            Roles.HR_ADMIN
        ],
    },
    {
        component: ManagerDashboard,
        path: '/dashboard',
        title: 'Manager Dashboard',
        exact: true,
        icon: "",
        permission: [
            Roles.MANAGER,
        ],
    },
    {
        component: UserDashboard,
        path: '/dashboard',
        title: 'User Dashboard',
        exact: true,
        icon: "",
        permission: [
            Roles.USER
        ],
    },
    {
        component: LeaveManagement,
        path: '/leave-management/:empId',
        title: 'LeaveManagement',
        exact: true,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
            Roles.MANAGER,
            Roles.USER
        ],
    },
    {
        component: TaskStats,
        path: '/task-stats/:empId',
        title: 'TaskStats',
        exact: true,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
            Roles.MANAGER,
            Roles.USER
        ],
    },
    {
        component: TaskEdit,
        path: '/task-edit/:empId/:d',
        title: 'TaskEdit',
        exact: true,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
            Roles.MANAGER,
            Roles.USER
        ],
    },
    {
        component: TaskView,
        path: '/task-view/:empId/:id',
        title: 'TaskView',
        exact: true,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
            Roles.MANAGER,
            Roles.USER
        ],
    },
    {
        component: Invoices,
        path: '/invoices',
        title: 'Invoices',
        exact: true,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
        ],
    },
    {
        component: CreateInvoice,
        path: '/create-invoice',
        title: 'Create Invoice',
        exact: true,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
        ],
    },
    {
        component: CreateInvoice,
        path: '/edit-invoice/:invoiceNo',
        title: 'Edit Invoice',
        exact: true,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
        ],
    },
    {
        component: InvoicePreview,
        path: '/invoice-preview/:invoiceNo',
        title: 'InvoicePreview',
        exact: true,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
        ],
    },
    {
        component: Billing,
        path: '/bills',
        title: 'Bills',
        exact: true,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
        ],
    },
    {
        component: CreateBill,
        path: '/create-bill',
        title: 'Create Bill',
        exact: true,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
        ],
    },
    {
        component: BillPreview,
        path: '/bill-preview/:billNo',
        title: 'Bills',
        exact: true,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
        ],
    },
    {
        component: CreateBill,
        path: '/edit-bill/:billNo',
        title: 'Bills',
        exact: true,
        permission: [
            Roles.SUPER_ADMIN,
            Roles.ADMIN,
            Roles.FINANCE_ADMIN,
            Roles.HR_ADMIN,
        ],
    },
];

export default routes;
