import EodReport, { PdfSvg } from "../../components/eod-report";
import { useCallback, useEffect, useState } from "react";
import { EodReportFormProps, Project } from "../../types";
import appApi from "../../services/api";
import ProjectCard, { SkeletonProjectCard } from "../../components/common/project-card";
import { useUserContext } from "../../context/user";
import Calendar from 'react-calendar';
import { date } from "../../constants";
import { useNavigate } from 'react-router-dom'
import { convertToISOFormat, FormattedDdMmDate } from "../../utils/helpers";
import { useParams } from "react-router-dom";
import Layout from "../../layout";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const TaskStats = () => {
    const { user } = useUserContext()
    const navigate = useNavigate()
    const { empId } = useParams()
    const [projects, setProjects] = useState<Project[]>([])
    const [eodReport, setEodReport] = useState<EodReportFormProps[]>([])
    const [value, onChange] = useState<Value>(new Date())
    const [loading, setLoading] = useState(false)

    const getProjectDetails = async (empID: string) => {
        // const data: Project[] = await appApi.getProjectDetails(empID)
        // if (!!data) {
        setLoading(false)
        setProjects([])
        // }
    }

    const getEodReports = async (empID: string) => {
        const data: any = await appApi.getEodReports(empID, "", "")
        if (data && data.reports) {
            setEodReport(data.reports)
        }
    }

    useEffect(() => {
        if (empId) {
            getProjectDetails(empId)
            getEodReports(empId)
        }
    }, [empId])

    const todayDate = FormattedDdMmDate(date.toString()).replaceAll("/", "-")
    const lastDayDate = new Date();
    lastDayDate.setDate(date.getDate() - 1);
    const lastDayDateString = FormattedDdMmDate(lastDayDate.toString()).replaceAll("/", "-")

    const tileContent = useCallback(({ date, view }: any) => {
        const d = FormattedDdMmDate(date.toString()).replaceAll("/", "-")
        const days = new Date(date).getDate()
        if ((todayDate === d || d === lastDayDateString) && empId === user.emp_id) {
            return (
                <AddButton onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/task-edit/${empId}/${days}`);
                }} />
            )
        }

        for (let i = 0; i < eodReport.length; i++) {
            const elementDate = FormattedDdMmDate(eodReport[i].report_date).replaceAll("/", "-")
            if (d === elementDate) {
                return (
                    <div className="absolute top-0 right-5 w-0 h-0">
                        <PdfSvg />
                    </div>
                )
            }
        }
    }, [eodReport, todayDate, empId, user.emp_id, lastDayDateString, navigate]);

    const holidayClassTile = useCallback(({ activeStartDate, date, view }: any) => {
        const d = FormattedDdMmDate(date.toString()).replaceAll("/", "-")
        if ((todayDate === d || d === lastDayDateString) && empId === user.emp_id) {
            return ["eod-report-add"]
        }
        const dx = new Date(date)
        if (dx.getDay() === 6) {
            return ["saturday-class"]
        }
        return null
    }, [todayDate, empId, lastDayDateString, user.emp_id])

    const onClickDayTile = (value: any) => {
        const d = convertToISOFormat(value)
        if (todayDate === d || d === lastDayDateString) {
            return
        }
        for (let i = 0; i < eodReport.length; i++) {
            const elementDate = eodReport[i].report_date
            if (d === elementDate) {
                navigate(`/task-view/${empId}/${eodReport[i].id}`);
                // const byteArray = base64ToUint8Array(eodReport[i].challenges);
                // const link = document.createElement('a');
                // const blob = new Blob([byteArray], { type: "application/octetstream" });
                // const url = window.URL;
                // let l2 = url.createObjectURL(blob);
                // link.href = l2;
                // link.download = `Eod-${eodReport[i].emp_id}-${eodReport[i].report_date}.pdf`;
                // link.dispatchEvent(new MouseEvent('click'));
            }
        }
    }

    const currentProject = projects.filter(item => item.isCurrent)
    const pastProjects = projects.filter(item => !item.isCurrent)
    const wfhEodReport = eodReport.filter(item => item.report_date === "WFH")

    return (
        <Layout>
            <div className="grid grid-cols-3 gap-6">
                <div className="white-box-shadow max-h-96 overflow-auto scroll-bar-width">
                    <h2 className="heading-3 pb-4">Current Projects</h2>
                    {loading ?
                        <SkeletonProjectCard /> :
                        currentProject.length ? currentProject.map((item, key) => {
                            const adaptedItem = {
                                ...item,
                                teamMembers: item.teamMembers.map(member => ({
                                    empID: member.id,
                                    firstName: member.name.split(' ')[0],
                                    lastName: member.name.split(' ')[1],
                                    role: member.role,
                                }))
                            };
                            return (
                                <ProjectCard key={key} {...adaptedItem} teamMembers={adaptedItem.teamMembers.map(member => ({ ...member, image: '' }))} />
                            )
                        }) : <div className="no-data col-span-12">No data found</div>
                    }
                </div>
                <div className="col-span-2 white-box-shadow">
                    <Calendar
                        calendarType='iso8601'
                        className={"task-calendar"}
                        onChange={onChange}
                        value={value}
                        tileContent={tileContent}
                        onClickDay={onClickDayTile}
                        tileClassName={holidayClassTile}
                    />
                </div>
                <div className="white-box-shadow max-h-[500px] overflow-auto scroll-bar-width">
                    <h2 className="heading-3 pb-4">Past Projects</h2>
                    {loading ?
                        <SkeletonProjectCard /> :
                        pastProjects.length ? pastProjects.map((item, key) => {
                            const adaptedItem = {
                                ...item,
                                teamMembers: item.teamMembers.map(member => ({
                                    empID: member.id,
                                    firstName: member.name.split(' ')[0],
                                    lastName: member.name.split(' ')[1],
                                    role: member.role,
                                }))
                            };
                            return (
                                <ProjectCard key={key} {...adaptedItem} teamMembers={adaptedItem.teamMembers.map(member => ({ ...member, image: '' }))} />
                            )
                        }) : <div className="no-data col-span-12">No data found</div>
                    }
                </div>
                <div className="white-box-shadow col-span-2">
                    <h2 className="heading-2 pb-4">Report Summary</h2>
                    <div className="profile-wfh bg-[#F7F9FCCC] border-b">
                        <div className="eod-report-table ">#</div>
                        <div className="eod-report-table ">Date</div>
                        <div className="eod-report-table ">Department</div>
                        <div className="eod-report-table ">Eod report</div>
                    </div>
                    <div className="profile-wfh overflow-auto scroll-bar-width max-h-80">
                        {eodReport.length ?
                            eodReport.map((item, index) => {
                                return <EodReport key={String(index)} {...item} index={String(index + 1)} />
                            }) :
                            <div className="no-data col-span-12">No data found</div>
                        }
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default TaskStats;

const AddButton = (props: { onClick: (e: any) => void }) => {
    return (
        <div className="w-full h-full absolute left-0 top-0 visible flex items-center justify-center z-30">
            <svg onClick={props.onClick} className="cursor-pointer h-8 w-8 hover:h-9 hover:w-9" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.333313" width="40" height="40" rx="20" fill="#7F56D9" />
                <g clipPath="url(#clip0_1617_8960)">
                    <path d="M19.0833 26.6666C19.0833 26.9981 19.215 27.316 19.4494 27.5505C19.6838 27.7849 20.0018 27.9166 20.3333 27.9166C20.6648 27.9166 20.9828 27.7849 21.2172 27.5505C21.4516 27.316 21.5833 26.9981 21.5833 26.6666V21.2499H27C27.3315 21.2499 27.6494 21.1182 27.8838 20.8838C28.1183 20.6494 28.25 20.3314 28.25 19.9999C28.25 19.6684 28.1183 19.3505 27.8838 19.116C27.6494 18.8816 27.3315 18.7499 27 18.7499H21.5833V13.3333C21.5833 13.0017 21.4516 12.6838 21.2172 12.4494C20.9828 12.2149 20.6648 12.0833 20.3333 12.0833C20.0018 12.0833 19.6838 12.2149 19.4494 12.4494C19.215 12.6838 19.0833 13.0017 19.0833 13.3333V18.7499H13.6666C13.3351 18.7499 13.0172 18.8816 12.7827 19.116C12.5483 19.3505 12.4166 19.6684 12.4166 19.9999C12.4166 20.3314 12.5483 20.6494 12.7827 20.8838C13.0172 21.1182 13.3351 21.2499 13.6666 21.2499H19.0833V26.6666Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_1617_8960">
                        <rect width="20" height="20" fill="white" transform="translate(10.3333 10)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}